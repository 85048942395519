import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import FooterScripts from "../components/FooterScripts";
import logoInverse from "../../assets/images/logo-inverse.png";
import footerbg from "../../assets/images/footer-bg.jpg";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faEnvelope,
  faAngleUp,
} from "@fortawesome/free-solid-svg-icons";

import RRSS from "../components/RRSS";

library.add(faAngleUp);

function Footer() {
  return (
    <div>
      <footer
        className="footer-style03"
        style={{ backgroundImage: `url(${footerbg})` }}
      >
        <Container>
          <Row>
            <Col sm={12} md={4} lg={6}>
              <div className="widget about-widget">
                <div className="widget-title">
                  <img src={logoInverse} alt="logotipo" />
                </div>
                <div className="widget-content">
                  <p>
                    Cooperativa andaluza, sin ánimo de lucro, comprometida con
                    una transición energética justa, sostenible y democrática.
                  </p>
                </div>
              </div>
            </Col>
            <Col sm={6} md={4} lg={3}>
              <div className="widget about-widget">
                <div className="widget-title">
                  <h2>Contacto</h2>
                </div>
                <div className="widget-content">
                  <ul className="list-unstyled">
                    <li>
                      <FontAwesomeIcon icon={faPhone} />
                      +34 624 28 63 63
                      <br />9 a 14h de lunes a viernes
                    </li>
                    <li>
                      <FontAwesomeIcon icon={faEnvelope} /> info@candelacoop.com
                    </li>
                  </ul>
                </div>
              </div>
            </Col>
            <Col sm={6} md={4} lg={3}>
              <div className="widget footer-social">
                <div className="widget-title">
                  <h2>Síguenos en</h2>
                </div>
                <RRSS />
              </div>
            </Col>
          </Row>
        </Container>
      </footer>

      <div className="footer-bottom">
        <Container>
          <div className="footer-bottom-inner">
            <Row>
              <Col sm={12} md={6}>
                <p>
                  &copy; Copyright{" "}
                  <script>document.write(new Date().getFullYear())</script>{" "}
                  Candela | Todos los derechos reservados.
                </p>
              </Col>
              <Col sm={12} md={6}>
                <ul className="list-unstyled footer-menu">
                  <li>
                    <Link to="/legal/aviso-legal#top">Aviso Legal</Link>
                  </li>
                  <li>
                    <Link to="/legal/politica-privacidad#top">
                      Política Privacidad
                    </Link>
                  </li>
                  {/* <li><Link to="/legal/politica-cookies">Política de Cookies</Link></li> */}
                </ul>
                <p id="back-top">
                  <a href="#top">
                    <FontAwesomeIcon icon={faAngleUp} />
                  </a>
                </p>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
      <FooterScripts />
    </div>
  );
}

export default Footer;
