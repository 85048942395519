import { useEffect, useState } from "react";
import { API } from "../services/Api.js";

export default function usePageContent({ pagename }) {
  const [content, setContent] = useState("");

  useEffect(() => {
    API.pages
      .get(pagename)
      .then((response) => {
        setContent(response.body);
      })
      .catch((err) => console.log(err));
  });

  return { content };
}
