import {
  faFacebookF,
  faTelegramPlane,
  faInstagram,
  faXTwitter,
  faBluesky,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function RRSS({ color = "text-white" }) {
  return (
    <div className="d-flex w-100 align-items-center gap-4">
      <a
        className={`${color} fs-5`}
        target="_blank"
        rel="noreferrer"
        href="https://facebook.com/candelacoop"
      >
        <FontAwesomeIcon icon={faFacebookF} />
      </a>
      <a
        className={`${color} fs-5`}
        target="_blank"
        rel="noreferrer"
        href="https://x.com/CandelaCoop"
      >
        <FontAwesomeIcon icon={faXTwitter} />
      </a>
      <a
        className={`${color} fs-5`}
        target="_blank"
        rel="noreferrer"
        href="https://t.me/candelacoop"
      >
        <FontAwesomeIcon icon={faTelegramPlane} />
      </a>
      <a
        className={`${color} fs-5`}
        target="_blank"
        rel="noreferrer"
        href="https://www.instagram.com/candelasociedadcooperativa/"
      >
        <FontAwesomeIcon icon={faInstagram} />
      </a>
      <a
        className={`${color} fs-5`}
        target="_blank"
        rel="noreferrer"
        href="https://bsky.app/profile/candelacooperativa.bsky.social"
      >
        <FontAwesomeIcon icon={faBluesky} />
      </a>
    </div>
  );
}
