import AwesomeSlider from "react-awesome-slider";
import withAutoplay from "react-awesome-slider/dist/autoplay";
import "react-awesome-slider/dist/styles.css";
import "react-awesome-slider/dist/custom-animations/cube-animation.css";

import AwesomeSliderStyles from "../../assets/css/awesomesliderstyles.css";
import slidehome01 from "../../assets/images/slidehome01.jpg";
import slidehome02 from "../../assets/images/slidehome02.jpg";
import slidehome03 from "../../assets/images/candela-bicicletas.jpg";
import slidehome04 from "../../assets/images/candela-macetas.jpg";
import Header from "../components/Header";
import usePageContent from "../../hooks/usePageContent";
import { HomeLastNewsCarrousel } from "../components/HomeLastNewsCarrousel";
import Footer from "../components/Footer";

function Home() {
  const AutoplaySlider = withAutoplay(AwesomeSlider);
  const { content: contentLeft } = usePageContent({ pagename: "home-left" });
  const { content: contentRight } = usePageContent({ pagename: "home-right" });
  document.title = "Inicio | CandelaCoop";

  return (
    <div className="Home">
      <Header />

      <AutoplaySlider
        animation="cubeAnimation"
        play={true}
        cancelOnInteraction={true} // should stop playing on user interaction
        interval={12000}
        cssModule={AwesomeSliderStyles}
        bullets={false}
      >
        <div data-src={slidehome01}>
          <div className="slider-item">
            <h1 className="slider-title">
              No más pobreza energética, la luz en nuestras manos
            </h1>
            <p className="slider-content">
              <span className="font-candela">candela</span>,{" "}
              <span className="text-handwrite">
                comunidad y justicia social
              </span>
            </p>
          </div>
        </div>
        <div data-src={slidehome02}>
          <div className="slider-item">
            <h1 className="slider-title">
              Cuidar el sol, el aire, la tierra, el agua
            </h1>
            <p className="slider-content">
              <span className="font-candela">candela</span>,{" "}
              <span className="text-handwrite">la vida</span>
            </p>
          </div>
        </div>
        <div data-src={slidehome03}>
          <div className="slider-item">
            <h1 className="slider-title">
              Dedica el tiempo a lo que importa.{" "}
            </h1>
            <p className="slider-content">
              <span className="font-candela">candela</span>,{" "}
              <span className="text-handwrite">las cosas sencillas</span>
            </p>
          </div>
        </div>
        <div data-src={slidehome04}>
          <div className="slider-item">
            <h1 className="slider-title">
              Raíces comunes para un futuro habitable
            </h1>
            <p className="slider-content">
              <span className="font-candela">candela</span>,{" "}
              <span className="text-handwrite">un compromiso</span>
            </p>
          </div>
        </div>
      </AutoplaySlider>

      <section className="about-ds">
        <div className="container">
          <div className="row">
            <div className="col-md-7 col-sm-6 about-ds-content">
              <div className="section-header03">
                <h2>
                  Bienvenidas a <span>Candela</span>
                </h2>
              </div>
              <div dangerouslySetInnerHTML={{__html: contentLeft}} />
            </div>
            <div className="col-md-5 col-sm-6 container-img-fluid">
              <div dangerouslySetInnerHTML={{__html: contentRight}} />
            </div>
          </div>
        </div>
      </section>

      <HomeLastNewsCarrousel />

      <Footer />
    </div>
  );
}

export default Home;
